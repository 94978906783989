import styles from './styles.module.scss';

import React from 'react';
import {NavLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Nav,
  NavLink as NavItemLink,
  NavItem,
  UncontrolledTooltip,
} from 'reactstrap';
import cx from 'classnames';
import {IconFA} from 'components/Icons';

const Tooltip = ({id, children, placement = 'top'}) => {
  return (
    <span>
      <span id={`tooltip-${id}`} className="text-secondary cursor-pointer">
        <IconFA name="info-circle" className="text-primary" />
      </span>

      <UncontrolledTooltip placement={placement} target={`tooltip-${id}`}>
        {children}
      </UncontrolledTooltip>
    </span>
  );
};
export default class ContentNav extends React.Component {
  static propTypes = {
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        href: PropTypes.string,
        to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
      })
    ).isRequired,
  };

  render() {
    const {items} = this.props;

    return (
      <Nav className={styles['content-nav']}>
        {items.map((item, index) => {
          return (
            <NavItem key={index} className={cx(item.className)}>
              {item.href ? (
                <NavItemLink
                  tag="a"
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.name}{' '}
                  {item.tooltip && <Tooltip id={index}>{item.tooltip}</Tooltip>}
                </NavItemLink>
              ) : (
                <NavItemLink
                  tag={NavLink}
                  exact={item.exact}
                  to={item.to}
                  target={item.target}
                >
                  {item.name}{' '}
                  {item.tooltip && <Tooltip id={index}>{item.tooltip}</Tooltip>}
                </NavItemLink>
              )}
            </NavItem>
          );
        })}
      </Nav>
    );
  }
}
