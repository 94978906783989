import React from 'react';
import PropTypes from 'prop-types';

import {uploadImage} from 'api/uploads';
import {FormGroup, FormText, Progress, Button} from 'reactstrap';

import {alertByError} from 'utils/alerts';
import {bytesToSize} from 'utils/numbers';
class Component extends React.Component {
  static propTypes = {
    onUploadComplete: PropTypes.func,
    onUploadProgress: PropTypes.func,
    maxSize: PropTypes.number,
  };

  static defaultProps = {
    disabled: false,
    maxSize: 5,
    accept: ['.jpg', '.jpeg', '.png'],
  };

  constructor(props) {
    super(props);

    this.state = {
      progress: 0,
      isFetching: false,
      value: '',
    };

    this.upload = this.upload.bind(this);
    this.uploadProgress = this.uploadProgress.bind(this);
  }

  upload(e) {
    e.preventDefault();

    const file = e.target.files[0];
    const {size} = file;

    const compare = this.props.maxSize * (1024 * 1024);
    const isLarge = size > compare;

    if (isLarge) {
      return alertByError({
        code: 'validation_error',
        message: {
          image: `The image must not be greater than ${bytesToSize(compare)}.`,
        },
      });
    }

    if (!file) return false;

    let formData = new FormData();
    formData.append('image', file);

    uploadImage(formData, this.uploadProgress).then(({data}) => {
      const image = JSON.stringify(data);

      this.props.onUploadComplete(image);

      this.setState({
        progress: 0,
        value: image,
      });
    });
  }

  uploadProgress(progressEvent) {
    const progress = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );

    this.setState({
      progress: progress,
    });
  }

  render() {
    const {progress, value} = this.state;
    const {className, required, accept, disabled, name, maxSize} = this.props;

    return (
      <FormGroup className="mb-0">
        <Button
          color="dark"
          onClick={() => this.refs.input.click()}
          disabled={disabled}
        >
          {progress > 0 || disabled ? 'Uploading..' : 'Upload'}
        </Button>
        <input
          ref="input"
          type="file"
          onChange={this.upload}
          className={className}
          required={required}
          accept={accept}
          style={{display: 'none'}}
          disabled={disabled}
        />

        <input type="hidden" name={name} value={value || ''} />

        <FormText>Maximum size allowed is {maxSize} MB of PNG and JPG</FormText>

        {progress > 0 && (
          <Progress striped animated color="info" value={progress} />
        )}
      </FormGroup>
    );
  }
}

export default Component;
