import React, {useState} from 'react';
import ReactCodeInput from 'react-verification-input';
import {FormGroup} from 'reactstrap';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';

// usage:
// for stateless use, name is required
// for stateful use do not add name

const propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};
const defaultProps = {
  className: '',
  placeholder: '',
};

function FormOtp(props) {
  const {name, className, placeholder, onChange} = props;
  const [code, setCode] = useState('');

  const handleChange = (value) => {
    if (onChange) {
      onChange(value);
    }

    setCode(value);
  };

  return (
    <FormGroup className={cx('mx-auto', styles['form-otp'], className)}>
      <ReactCodeInput
        onChange={handleChange}
        placeholder={placeholder}
        classNames={{
          container: 'react-verification-input__container',
          character: 'react-verification-input__character notranslate',
          characterInactive: 'react-verification-input__character--inactive',
          characterSelected: 'react-verification-input__character--selected',
        }}
      />

      {!!name && <input type="hidden" name={name} value={code} />}
    </FormGroup>
  );
}

FormOtp.propTypes = propTypes;
FormOtp.defaultProps = defaultProps;

export default FormOtp;
