import 'rmc-steps/assets/index.css';
import 'styles/main.scss';

import React, {useEffect} from 'react';
import {Router, useLocation} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import routes from 'routes';

import {ROUTER_SCROLL_TYPES} from 'configs';
import renderRoutes from 'utils/render-routes';
import {setAxiosConfig} from 'utils/http';
import {setDayJs} from 'utils/dates';

import {AuthProvider} from 'contexts/AuthContext';
import {ConsentProvider} from 'contexts/ConsentContext';
import {ViewportProvider} from 'contexts/ViewportContext';
import {PageProvider} from 'contexts/PageContext';
import {ToastProvider} from 'contexts/ToastContext';
import {SidebarProvider} from 'contexts/SidebarContext';
import {CommonDataProvider} from 'contexts/CommonDataContext';
import {LocaleProvider} from 'contexts/LocaleContext';
import {CaptchaProvider} from './contexts/CaptchaContext';
import {MailingProvider} from 'contexts/MailingContext';
import {ProgressProvider} from 'contexts/ProgressContext';

import {Page} from 'components/Pages';
import TemplateVersioningNotifyUpdateModal from 'modules/import-settings/template-versioning/TemplateVersioningNotifyUpdateModal';
// import {AgreementsNoticeModal} from 'components/Agreements';

import {ErrorBoundary} from 'react-error-boundary';
import Errors from 'views/errors/Errors';
import {ERROR_STATUS} from 'configs';
import GoogleTranslateElement from 'components/GoogleTranslateElement';
import {OnboardProvider} from 'contexts/OnboardContext';

const browserHistory = createBrowserHistory();

function ErrorFallback({error, resetErrorBoundary}) {
  let errCode = ERROR_STATUS['404'];
  if (
    error.message.includes('removeChild') ||
    error.message.includes('insertBefore')
  ) {
    errCode = ERROR_STATUS['google_translate'];
  }
  return (
    <Router history={createBrowserHistory({forceRefresh: true})}>
      <PageProvider>
        <Page>
          <Errors errCode={errCode} onTryAgain={resetErrorBoundary} />
        </Page>
      </PageProvider>
    </Router>
  );
}

setAxiosConfig();
setDayJs();

function ScrollToTop() {
  const {pathname, search, hash, state} = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const element = document.getElementById(hash);

        if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
      } else if (
        state &&
        !!state.scroll &&
        state.scroll === ROUTER_SCROLL_TYPES.scroll
      ) {
        const id = hash.replace('#', '');
        const element =
          document.getElementById(hash) || document.getElementById(id);

        if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
        return;
      } else if (!state || state.scroll !== ROUTER_SCROLL_TYPES.stay) {
        window.scrollTo(0, 0);
      }
    }, 10);
  }, [pathname, search, state, hash]);

  useEffect(() => {
    if (hash) {
      // Push onto callback queue so it runs after the DOM is updated,
      // this is required when navigating from a different page so that
      // the element is rendered on the page before trying to getElementById.
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element =
          document.getElementById(hash) || document.getElementById(id);

        if (element) {
          element.scrollIntoView({behavior: 'smooth', block: 'center'});
        }
      }, 200);
    }
  }, [hash]);

  return null;
}

function App() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div className="App">
        <Router history={browserHistory}>
          <ScrollToTop />

          <ConsentProvider>
            <AuthProvider>
              <ViewportProvider>
                <PageProvider>
                  <SidebarProvider>
                    <CommonDataProvider>
                      <LocaleProvider>
                        <CaptchaProvider>
                          <ToastProvider>
                            <ProgressProvider>
                              <MailingProvider>
                                <OnboardProvider>
                                  <Page>
                                    <GoogleTranslateElement />

                                    {renderRoutes(routes)}
                                  </Page>
                                  <TemplateVersioningNotifyUpdateModal />
                                </OnboardProvider>

                                {/* <AgreementsNoticeModal /> */}
                              </MailingProvider>
                            </ProgressProvider>
                          </ToastProvider>
                        </CaptchaProvider>
                      </LocaleProvider>
                    </CommonDataProvider>
                  </SidebarProvider>
                </PageProvider>
              </ViewportProvider>
            </AuthProvider>
          </ConsentProvider>
        </Router>
      </div>
    </ErrorBoundary>
  );
}

export default App;
