import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';
import {IconFA} from 'components/Icons';

class ContentPaginationState extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  static defaultProps = {
    className: 'justify-content-end',
    handleChangeOffset: () => {},
  };

  static propTypes = {
    totalPages: PropTypes.oneOfType([PropTypes.number]).isRequired,
    offset: PropTypes.number,
    handleChangeOffset: PropTypes.func.isRequired,
    className: PropTypes.string,
  };

  handleChange({selected}) {
    const {handleChangeOffset} = this.props;

    handleChangeOffset(selected);
  }

  render() {
    const {totalPages, className, offset} = this.props;

    return (
      <ReactPaginate
        pageCount={Number(totalPages) || 0}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        forcePage={Number(offset)}
        activeClassName={'active'}
        containerClassName={classNames('pagination mt-3', className)}
        onPageChange={this.handleChange}
        // hrefBuilder={this.buildHref}
        pageClassName="page-item"
        pageLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        nextLabel={<IconFA name="angle-right" label="Next" />}
        previousClassName="page-item"
        previousLinkClassName="page-link"
        previousLabel={<IconFA name="angle-left" label="Previous" />}
        breakClassName="page-item"
        breakLabel={<span className="page-link">...</span>}
      />
    );
  }
}

export default ContentPaginationState;
