import styles from './styles.module.scss';

import React from 'react';
import classNames from 'classnames';
import {LoadingPage} from '../Loading';

const defaultProp = {
  tag: 'div',
  isLoading: false,
};

function ContentBox({
  title,
  isLoading,
  tag,
  className,
  innerClassName,
  id,
  children,
  description,
}) {
  const _className = classNames(styles['content-box'], {
    [className]: !!className,
  });

  const _innerClassName = classNames(styles['content-box__inner'], {
    [innerClassName]: !!innerClassName,
  });

  return React.createElement(
    tag,
    {id, className: _className},
    <>
      <LoadingPage isLoading={isLoading} />
      {(title || description) && (
        <div className={styles['content-box__title-wrapper']}>
          {title && (
            <h2
              className={classNames(styles['content-box__title'], {
                'mb-1': !!description,
              })}
            >
              {title}
            </h2>
          )}
          {description && <p className="text-secondary mb-4">{description}</p>}
        </div>
      )}

      <div className={_innerClassName}>{children}</div>
    </>
  );
}

ContentBox.defaultProps = defaultProp;

export default ContentBox;
