import React from 'react';
import styles from './styles.module.scss';
import {IconFA} from 'components/Icons';
import {keys} from 'lodash/object';
import PropTypes from 'prop-types';
import {ImageCompanyLogo} from 'components/Images';
import cx from 'classnames';

import {IMAGE_WIDTHS} from 'configs';

ContentAvatar.propTypes = {
  file: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(keys(IMAGE_WIDTHS)),
};

ContentAvatar.defaultProps = {
  editable: false,
};

function ContentAvatar(props) {
  const {file, editable, companyType, onClick} = props;

  return (
    <div
      className={cx(styles['content-avatar'], {
        [styles['content-avatar--editable']]: editable,
      })}
      onClick={() => {
        if (onClick && editable) onClick();
      }}
    >
      <ImageCompanyLogo
        size="md"
        file={file}
        companyType={companyType}
        className="rounded-circle"
      />

      {editable && (
        <div className={styles['content-avatar__status']}>
          <div className={styles['content-avatar__status__icon']}>
            <IconFA name="pencil" className="text-muted" />
          </div>
        </div>
      )}
    </div>
  );
}

export default ContentAvatar;
