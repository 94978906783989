import styles from './styles.module.scss';

import React from 'react';
import PropTypes from 'prop-types';
import {isNumber} from 'lodash/lang';
import classNames from 'classnames';
import {UncontrolledTooltip} from 'reactstrap';
import {kebabCase} from 'lodash';
import NumberFormat from 'react-number-format';

import {Container} from 'reactstrap';

export default class ContentTitle extends React.Component {
  static defaultProps = {
    tag: 'h2',
    fluid: true,
    grid: true,
  };

  static propTypes = {
    tag: PropTypes.string,
    count: PropTypes.number,
  };

  get titleCount() {
    const {count} = this.props;

    return (
      <span
        className={classNames(styles['content-title__count'], 'notranslate')}
      >
        <NumberFormat
          displayType="text"
          value={count}
          thousandSeparator
          renderText={(value) => value}
        />
      </span>
    );
  }

  get title() {
    const {title, tag, count, tooltip} = this.props;

    return React.createElement(
      tag,
      {
        className: styles['content-title__title'],
      },
      <React.Fragment>
        <span id={`tooltip-${kebabCase(title)}`}>
          {title}
          {isNumber(count) && this.titleCount}
        </span>
        {!!tooltip && (
          <UncontrolledTooltip
            hideArrow
            target={`tooltip-${kebabCase(title)}`}
            placement="right"
          >
            <p className="text-left mb-0">{tooltip}</p>
          </UncontrolledTooltip>
        )}
      </React.Fragment>
    );
  }

  get desc() {
    const {description} = this.props;

    return React.createElement(
      'p',
      {className: styles['content-title__desc']},
      description
    );
  }

  render() {
    const {title, fluid, className, children, description} = this.props;

    return (
      <div className={classNames(styles['content-title'], className)}>
        <Container fluid={fluid}>
          {title && this.title}
          {description && this.desc}
          {children}
        </Container>
      </div>
    );
  }
}
