import React from 'react';
import {useLocation} from 'react-router-dom';
import urlJoin from 'url-join';
import {useCaptchaCode} from 'contexts/CaptchaContext';

function CaptchaAlternativeLink() {
  const location = useLocation();
  const {captchaCode} = useCaptchaCode();

  const pathname = location.pathname || window.location.pathname;
  const code = captchaCode === 'g' ? 'h' : 'g';

  const url = urlJoin(pathname, `?captcha=${code}`);

  return (
    <p className="mb-0">
      Captcha not working? Click{' '}
      <div
        className="btn-link d-inline"
        style={{cursor: 'pointer'}}
        onClick={() => window.location.replace(url)}
      >
        here
      </div>
    </p>
  );
}

export default CaptchaAlternativeLink;
