import http, {makeSearchParams} from 'utils/http';
import {API_VU_URL} from 'configs';

export const httpVu = (method = 'GET', url, data, options = {}) =>
  http(method, url, data, {...options, baseURL: API_VU_URL});

export const addSearchAccountHistory = (data) => {
  return http('POST', `search/account-history`, data);
};

export const getSearchSuggestCompany = ({query, status = 'active'}) => {
  return httpVu('GET', `search/suggest-company`, {
    q: query,
    filter: {company_status: status},
  });
};

export const getSearchSuggestCertificate = ({query, status = 'active'}) => {
  return httpVu('GET', `search/suggest-certificate`, {
    q: query,
    filter: {status: status},
  });
};

export const getDashboardSearchLimit = (data) => {
  return http('GET', 'account/dashboard/search-limit', makeSearchParams(data));
};
