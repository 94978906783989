import React from 'react';
import PropTypes from 'prop-types';
import serialize from 'form-serialize';
import {Form} from 'reactstrap';
import isEmpty from 'lodash/isEmpty';

class Component extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (e, callback) => {
    e.preventDefault();

    const {serializeOptions} = this.props;

    const form = e.target;

    // form.checkValidity
    // Returns true if form contains valid data.
    const selects = form.getElementsByClassName('react-select--invalid');
    if (form.checkValidity() === false || !isEmpty(selects)) {
      form.classList.add('was-validated');
      return false;
    }

    const data = serialize(form, serializeOptions);

    callback(data);
  };

  render() {
    const {serializeOptions, onSubmit, children, ...rest} = this.props;

    return (
      <Form
        onSubmit={(e) => this.handleSubmit(e, onSubmit)}
        {...rest}
        noValidate
      >
        {children}
      </Form>
    );
  }
}

Component.defaultProps = {
  serializeOptions: {hash: true, empty: false},
};

export default Component;
