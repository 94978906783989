import React, {useState, useEffect} from 'react';
import cx from 'classnames';
import AsyncSelect from 'react-select/async';
import {IconFA} from 'components/Icons';
import {isEmpty} from 'lodash';

const getStyles = (customStyles) => {
  return {
    control: (styles, {isDisabled}) => ({
      ...styles,
      borderRadius: 0,
      borderColor: '#ced4da',
      color: isDisabled && '#6c757d',
      backgroundColor: isDisabled ? '#e9ecef' : 'white',
    }),
    option: (styles, {isDisabled}) => ({
      ...styles,
      borderRadius: 0,
      color: !isDisabled && '#000',
    }),
    input: (styles) => styles,
    placeholder: (styles) => styles,
    singleValue: (styles) => styles,
    menu: (styles) => ({
      ...styles,
      zIndex: 5,
    }),
    ...customStyles,
  };
};

const defaultProps = {
  components: {
    DropdownIndicator: () => {
      return <IconFA name="search" className="mr-2 text-secondary" />;
    },
    IndicatorSeparator: () => null,
  },
  className: 'react-select-container',
  classNamePrefix: 'react-select',
};

function SearchAsyncBase(props) {
  const {
    styles,
    isDisabled = false,
    onChange,
    className,
    required,
    value,
    defaultValue,
    ...rest
  } = props;

  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    if (!isEmpty(value)) setSelectedValue(value || defaultValue);
  }, [value]);

  const _className = cx(className, {
    'react-select--valid':
      required && !isDisabled ? !isEmpty(selectedValue) : !isDisabled,
    'react-select--invalid':
      required && !isDisabled ? isEmpty(selectedValue) : false,
  });

  function handleChange(selected) {
    setSelectedValue(selected);
    if (onChange) onChange(selected);
  }

  return (
    <AsyncSelect
      value={selectedValue}
      className={_className}
      onChange={handleChange}
      required={required}
      isDisabled={isDisabled}
      styles={getStyles(styles)}
      {...rest}
    />
  );
}

SearchAsyncBase.defaultProps = defaultProps;

export default SearchAsyncBase;
