import React, {useState} from 'react';
import {
  InputGroup,
  Input,
  Button,
  Row,
  Col,
  Label,
  Spinner,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
// import {ContentTable} from 'components/Contents';
// import LabelStatus from 'components/Labels/LabelStatus';

import dayjs from 'dayjs';
import urlJoin from 'url-join';

// import {ACTIVATION_LINK_STATUS} from 'configs/statuses';
import {ACTIVATE_URL} from 'configs';
import {alertByError} from 'utils/alerts';
import {IconFA} from 'components/Icons';

function FormActivateHistory(props) {
  const {isLoading, data, /*isLoadingHistory, historyData,*/ isAdding, onAdd} =
    props;
  const {
    company_activation_token,
    company_activation_type,
    company_activation_expiry,
  } = data || {};

  const [isCopied, setIsCopied] = useState(false);

  const activationLink =
    company_activation_type || company_activation_token
      ? urlJoin(ACTIVATE_URL, company_activation_type, company_activation_token)
      : '';

  // API returns dates as unix timestamp seconds, so we need to convert it to milliseconds before calculating for the remaining duration
  const duration = dayjs.duration(
    dayjs(company_activation_expiry).format('x') - dayjs()
  );

  const monthsRemaining = duration.asMonths();
  const daysRemaining = duration.asDays();
  const hoursRemaining = duration.asHours();
  const minutesRemaining = duration.asMinutes();

  const timeRemaining =
    monthsRemaining > 1
      ? `${Math.ceil(monthsRemaining)} months remaining before link expires`
      : daysRemaining > 1
      ? `${Math.ceil(daysRemaining)} days remaining before link expires`
      : hoursRemaining > 1
      ? `${Math.ceil(hoursRemaining)} hours remaining before link expires`
      : minutesRemaining > 1
      ? `${Math.ceil(minutesRemaining)} minutes remaining before link expires`
      : 'Activation link expires after 100 days upon generation';

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(activationLink);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (e) {
      await alertByError(e);
    }
  };

  return (
    <>
      <Row>
        <Label lg={3}>Activation Link</Label>

        <Col lg={9}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <IconFA name="link" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="No Activation Link Generated"
              value={activationLink}
              readOnly
            />
            <Button
              color="primary"
              className="mr-1"
              onClick={handleCopyToClipboard}
              disabled={isLoading || isAdding || !activationLink}
              outline
            >
              {isLoading || isAdding ? (
                <Spinner size="sm" />
              ) : isCopied ? (
                'Copied!'
              ) : (
                'Copy'
              )}
            </Button>
            <Button
              color="primary"
              onClick={onAdd}
              disabled={isLoading || isAdding || activationLink}
            >
              Generate Link
            </Button>
          </InputGroup>
          <small className="text-muted">{timeRemaining}</small>
        </Col>
      </Row>

      {/*Temporarily remove the expiration period for CB activation links (IAFCS20-3405)*/}
      {/* <Row>
        <Label lg={3} className="pt-3">
          Link History
        </Label>

       <Col lg={9}>
          <ContentTable
            head={['Status', 'Activation Links', 'Date Generated']}
            minHeight={20}
            className="m-0"
            isFetching={isLoadingHistory}
            emptyMessage={
              <span className="text-muted">No expired links yet</span>
            }
          >
            {historyData.map((item, index) => {
              const {
                company_activation_token,
                company_activation_type,
                created_at,
              } = item;

              return (
                <tr key={index}>
                  <td>
                    <LabelStatus
                      status={ACTIVATION_LINK_STATUS.expired}
                      color="danger"
                    />
                  </td>
                  <td>
                    {urlJoin(
                      ACTIVATE_URL,
                      company_activation_type,
                      company_activation_token
                    )}
                  </td>
                  <td>
                    <span>{date(created_at, 'MM/DD/YYYY')}</span>{' '}
                    <span className="text-muted">
                      {date(created_at, 'hh:mm:ss')}
                    </span>
                  </td>
                </tr>
              );
            })}
          </ContentTable>
        </Col>
      </Row> */}
    </>
  );
}

export default FormActivateHistory;
