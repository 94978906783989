import styles from './styles.module.scss';

import React from 'react';
import {withRouter, matchPath} from 'react-router';
import {Link} from 'react-router-dom';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import truncate from 'voca/truncate';

const bakeBread = (pathname, tree) => {
  let items = [];

  tree.forEach((item) => {
    const isMatch = matchPath(pathname, {
      exact: false,
      path: item.match,
    });

    if (isMatch) {
      items.push({
        active: isMatch.isExact,
        to: item.to,
        text: item.text,
      });

      if (item.routes) {
        items = items.concat(bakeBread(pathname, item.routes));
      }
    }
  });

  return items;
};

class ContentBreadcrumb extends React.Component {
  get items() {
    const {location, tree} = this.props;
    const breadcrumbs = bakeBread(location.pathname, tree);

    return breadcrumbs.map((item, index) => {
      if (item) {
        const {to, text, active} = item;
        const trimmed = truncate(text, 30);

        return (
          <BreadcrumbItem key={index} active={active}>
            {to && !active ? <Link to={to}>{trimmed}</Link> : trimmed}
          </BreadcrumbItem>
        );
      }

      return null;
    });
  }

  render() {
    return (
      <Breadcrumb className={styles['content-breadcrumb']}>
        {this.items}
      </Breadcrumb>
    );
  }
}

export default withRouter(ContentBreadcrumb);
