import React from 'react';
import classNames from 'classnames';

function FormSection(props) {
  const {className, children, ...rest} = props;

  return (
    <section
      className={classNames({
        [className]: !!className,
      })}
      {...rest}
    >
      {children}
    </section>
  );
}

export default FormSection;
