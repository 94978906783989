import React, {useState} from 'react';
import {
  Alert,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';
import {useAuth} from 'contexts/AuthContext';
import {alertByError} from 'utils/alerts';
import {getDashboardSearchLimit} from 'api/search';
import {getAccountHistory} from 'api/accounts';
import {COMPANY_TYPE} from 'configs/types';
import {ENABLE_SUBSCRIPTION} from 'configs/enable-features';
import {IconFA} from 'components/Icons';
import {components} from 'react-select';
// import {useSubscription} from 'contexts/SubscriptionContext';
import {SearchAsyncBase} from '../FormBases';
import {map} from 'lodash';
// import {SUBSCRIPTION_PLANS_TYPE} from 'configs/subscription';
import {getProgressColor} from 'components/Progress/ProgressStats';
import {getFormattedNumber} from 'utils/numbers';
import styles from './styles.module.scss';
import cx from 'classnames';
import {useViewport} from 'contexts/ViewportContext';
import {VIEWPORT_WIDTHS} from 'configs';

const SEARCH_TYPE = {
  company: 'company',
  certification: 'certification',
};

const SEARCH_LIMIT_TYPE = {
  company: 'ce',
  certification: 'cert',
};

const Control = ({children, ...props}) => {
  const {selectProps} = props;
  const {isCert, isMobile, onToggleMenu, onChangeSearchType} = selectProps;

  function handleMouseDown(e) {
    e.stopPropagation();
    onToggleMenu(false);
  }

  return (
    <components.Control {...props}>
      {isMobile && (
        <div
          className="pl-2 text-primary cursor-pointer"
          onMouseDown={(e) => handleMouseDown(e)}
          onTouchEnd={(e) => handleMouseDown(e)}
          style={{
            '&:focus': {
              border: 0,
            },
          }}
        >
          <UncontrolledButtonDropdown
            down="down"
            className="d-flex align-items-center"
          >
            <DropdownToggle
              color="white"
              className="p-0 m-0 text-primary small"
              size="sm"
              outline
            >
              <IconFA name={isCert ? 'file' : 'building'} />
              <IconFA className="ml-1" name="chevron-down" />
            </DropdownToggle>

            <DropdownMenu className="py-0 mt-3 border">
              <DropdownItem
                className={cx(styles['search-select__dropdown--item'])}
                value="certified-entity"
                onClick={(e) => onChangeSearchType(e, false)}
              >
                Company Name
              </DropdownItem>
              <DropdownItem
                className={cx(styles['search-select__dropdown--item'])}
                value="certification"
                onClick={(e) => onChangeSearchType(e, false)}
              >
                Certification ID
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      )}
      {children}
    </components.Control>
  );
};

function SearchSelectMenuList(props) {
  const {selectProps, isLoading, limit, isCert, isPremium, ...rest} = props;
  const {inputValue} = selectProps;

  const {try: limitTry, max: limitMax} = limit || {};
  const limitReached = limitTry === limitMax;

  let text = limitReached ? (
    'You have insufficient credits.'
  ) : (
    <span>
      You have used{' '}
      <strong>
        {getFormattedNumber(limitTry)}/{getFormattedNumber(limitMax)}
      </strong>{' '}
      of your verifications
    </span>
  );

  const alertColor = getProgressColor(limitTry, limitMax);

  return (
    <div className="p-3 d-flex flex-column">
      {isLoading ? (
        <span className="text-muted align-self-center">Loading...</span>
      ) : (
        <div>
          {!isPremium && !(limitMax < 0) && (
            <>
              <Alert fade={false} color={alertColor}>
                <IconFA
                  name={limitReached ? 'exclamation-circle' : 'info-circle'}
                  className="mr-2"
                />
                {text}
              </Alert>

              <hr />
            </>
          )}

          {!inputValue && (
            <h6 className="font-weight-bold text-dark">Recent Searches</h6>
          )}

          <components.MenuList {...rest} />
        </div>
      )}
    </div>
  );
}

function SearchSelect(props) {
  const {
    type,
    additionalComponents,
    keyword,
    onChangeType,
    dropdownOpen,
    ...rest
  } = props;
  const {size} = useViewport();
  const {isAuth, isMainAccount, companyType} = useAuth();
  // commented out since this file doesn't exists in DC
  // const {isSubscribed, subscriptionPlan} = useSubscription();
  const isCert = type === SEARCH_TYPE.certification;
  const isCompany = companyType === COMPANY_TYPE.company;
  const isCE = companyType === COMPANY_TYPE.ce;

  const isCompanyEnabledSubscription =
    (isCompany || isCE) && ENABLE_SUBSCRIPTION;

  // commented out since this file doesn't exists in DC
  // const isPremium = subscriptionPlan === SUBSCRIPTION_PLANS_TYPE.premium;

  const [isLoading, setIsLoading] = useState(false);
  const [limit, setLimit] = useState({});
  const [recentSearch, setRecentSearch] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isMobile = VIEWPORT_WIDTHS[size] < VIEWPORT_WIDTHS['xl'];

  async function getLimit() {
    try {
      const {data} = await getDashboardSearchLimit(
        isMainAccount ? {type: SEARCH_LIMIT_TYPE[type]} : {}
      );

      setLimit(data);
    } catch (err) {
      await alertByError(err);
    }
  }

  async function getRecentSearches() {
    try {
      const {data} = await getAccountHistory({
        filter: {type: SEARCH_TYPE[type]},
      });

      if (isCert) {
        return setRecentSearch(
          map(data, (item) => ({
            cert_number: item.certification_number,
            ces: map(item.mdb_ces, (i) => i.company_name),
            company_certifications_id: item.company_certifications_id,
          }))
        );
      }

      setRecentSearch(data);
    } catch (err) {
      await alertByError(err);
    }
  }

  async function onMenuOpen() {
    setIsMenuOpen(!isMenuOpen);

    setIsLoading(true);
    if (isAuth) {
      await getLimit();

      if (isCompanyEnabledSubscription) {
        await getRecentSearches();
      }
    }
    setIsLoading(false);
  }

  const styles = {
    control: (styles) => {
      return {
        ...styles,
        borderColor: '#0077c0',
        borderRadius: isMobile ? '50px 50px 50px 50px' : '0px 50px 50px 0px',
        '&:hover': {
          borderColor: null,
        },
      };
    },
    placeholder: (styles) => ({
      ...styles,
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    }),
  };

  const selectComponents = {
    ...(isAuth && {Control}),
    IndicatorSeparator: null,
    DropdownIndicator: () => (
      <IconFA name="search" className="mr-2 text-secondary" />
    ),
    MenuList: (props) => {
      return isCompanyEnabledSubscription ? (
        <SearchSelectMenuList
          {...props}
          limit={limit}
          isCert={isCert}
          isLoading={isLoading}
          isPremium={false} // defaulted to false since dc doesn't have the same functionality
          isSubscribed={false} // defaulted to false since dc doesn't have the same functionality
          recentSearch={recentSearch}
        />
      ) : (
        <components.MenuList {...props} />
      );
    },
    ...additionalComponents(limit),
  };

  const updatesProps = {
    styles,
    isCert,
    isMobile,
    menuIsOpen: isMenuOpen,
    onChangeSearchType: onChangeType,
    onMenuClose: () => setIsMenuOpen(false),
    onToggleMenu: (value) => setIsMenuOpen(value),
    onBlur: () => setIsMenuOpen(!!dropdownOpen && !isMenuOpen),
  };

  const searchProps = {
    cacheOptions: isCompanyEnabledSubscription && !keyword,
    components: selectComponents,
    onMenuOpen: onMenuOpen,
    defaultOptions: recentSearch,
    ...(isAuth && updatesProps),
    ...rest,
  };

  return <SearchAsyncBase {...searchProps} />;
}

export default SearchSelect;
