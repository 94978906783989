import React from 'react';
import {getABLibraryStandards} from 'api/accreditation-bodies';
import {SearchAsyncBase} from 'components/Forms/FormBases';

const defaultProps = {
  name: 'standards_id',
  placeholder: 'i.e. ISO 9001:2018',
};

function SearchStandardLibrary(props) {
  const {filters, placeholder, required, ...rest} = props;

  async function loadOptions(inputValue = '') {
    try {
      const {data} = await getABLibraryStandards({
        ...filters,
        limit: -1,
        search: inputValue.trimEnd(),
        order: 'ASC',
        order_by: 'standards_name',
        standards_status: 'active',
      });

      return data;
    } catch (error) {
      return [];
    }
  }

  return (
    <SearchAsyncBase
      isClearable
      required={required}
      loadOptions={loadOptions}
      getOptionLabel={(option) => option.standards_name}
      getOptionValue={(option) => option.standards_id}
      openMenuOnClick={true}
      placeholder={placeholder}
      noOptionsMessage={({inputValue}) =>
        inputValue
          ? `There is no result for '${inputValue}'.`
          : 'Search and select the correct standard including version.'
      }
      {...rest}
    />
  );
}

SearchStandardLibrary.defaultProps = defaultProps;

export default SearchStandardLibrary;
