import styles from './styles.module.scss';

import React from 'react';
import classNames from 'classnames';
import {isFunction} from 'lodash/lang';

function FieldsetLegend(props) {
  const {className, ...rest} = props;
  return (
    <legend
      className={classNames(styles['form__fieldset__title'], {
        [className]: !!className,
      })}
      {...rest}
    />
  );
}

function FieldsetDescription(props) {
  const {className, ...rest} = props;
  return (
    <p
      className={classNames(styles['form__fieldset__description'], {
        [className]: !!className,
      })}
      {...rest}
    />
  );
}

function FormFieldset({
  className,
  title,
  description,
  children,
  disabled,
  titleClassName,
  ...rest
}) {
  return (
    <fieldset
      className={classNames({
        [className]: !!className,
      })}
      key={disabled}
      disabled={disabled}
      {...rest}
    >
      <div>
        {title && (
          <FieldsetLegend className={titleClassName}>{title}</FieldsetLegend>
        )}
        {description && (
          <FieldsetDescription>{description}</FieldsetDescription>
        )}
        {isFunction(children) ? children({isEdit: true}) : children}
      </div>
    </fieldset>
  );
}

export default FormFieldset;
