import http, {makeSearchParams} from 'utils/http';

export const getAccreditationBodies = (queries) => {
  return http('GET', '/ab', makeSearchParams(queries));
};

export const getAccreditationBody = (id) => {
  return http('GET', `g/ab/${id}`);
};

export const getPublicAccreditationBodies = (queries) => {
  return http('GET', 'g/ab', makeSearchParams(queries));
};

export const getPublicAccreditationScopes = (id, queries) => {
  return http('GET', `g/ab/${id}/scopes`, makeSearchParams(queries));
};

export const getPublicAccreditationBodiesByCb = (cbId, queries) => {
  return http('GET', `g/cb/${cbId}/ab`, makeSearchParams(queries));
};

/**
 * Get accreditation bodies who has standards.
 * @param queries
 * @returns {*}
 */
export const getAccreditationBodiesHaveStandards = (queries) => {
  return http('GET', 'cb/ab', makeSearchParams(queries));
};

export const getPublicABLocations = (abId, queries) => {
  return http('GET', `g/ab/${abId}/locations`, makeSearchParams(queries));
};

export const getAccreditationBodiesStandards = (queries) => {
  return http('GET', 'ab/ab-standards', makeSearchParams(queries));
};

export const getAccreditationBodiesStandard = (standardId) => {
  return http('GET', `ab/ab-standards/${standardId}`);
};

export const deleteAccreditationBodiesStandard = (standardId) => {
  return http('DELETE', `ab/ab-standards/${standardId}`);
};

export const deletePermanentABStandard = (standardId) => {
  return http('DELETE', `ab/ab-standards/${standardId}/permanent`);
};

export const updateAccreditationBodiesStandards = (standardId, data) => {
  return http('PUT', `ab/ab-standards/${standardId}`, data);
};

export const assignABStandard = (data) => {
  return http('POST', 'ab/ab-standards', data);
};

export const getABLibraryStandards = (queries) => {
  return http('GET', 'ab/ab-standards/lib', makeSearchParams(queries));
};

export const addABStandardToLibrary = (data) => {
  return http('POST', 'ab/ab-standards/add-to-lib', data);
};

//MNCB
export const getAccreditationBodiesHaveStandardsMncb = (cbId, queries) => {
  return http('GET', `cb/${cbId}/accreditation`, makeSearchParams(queries));
};

export const postABBranchActivation = (abId) => {
  return http('POST', `/activation/ab/branch/${abId}`);
};
