import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {EXTERNAL_URL} from 'configs';
import {Button} from 'reactstrap';
import {IconFA} from '../Icons';
import {TEMPLATE_VERSIONS, getTemplateUrlByName} from 'configs/ExternalUrl';

const {CERT_TEMPLATES, CB_TEMPLATES} = EXTERNAL_URL;

const propTypes = {
  type: PropTypes.oneOf([...CERT_TEMPLATES, ...CB_TEMPLATES]).isRequired,
};

const defaultProps = {
  icon: false,
  color: 'primary',
  outline: false,
  version: TEMPLATE_VERSIONS['1.0'],
};

function ButtonDataTemplate(props) {
  const {icon, type, version, children, ...rest} = props;

  return (
    <Button
      tag={Link}
      href={getTemplateUrlByName(type, version) || '/'}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      <IconFA name="file" className="fa-lg mr-1" />
      {children}
    </Button>
  );
}

ButtonDataTemplate.propTypes = propTypes;
ButtonDataTemplate.defaultProps = defaultProps;

export default ButtonDataTemplate;
