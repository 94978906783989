import React, {useState, useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import {ContentAvatar} from 'components/Contents';
import {FormField, FormImageEditorModal} from 'components/Forms';
import {ONLY_ACCEPTED_IMAGE_KEYS, ONLY_ACCEPTED_IMAGE_LABELS} from 'configs';
import {isString} from 'lodash/lang';
import {alertByError} from 'utils/alerts';
import {uploadImage} from 'api/uploads';

FormImageEditorField.defaultProps = {
  acceptedFileTypes: ONLY_ACCEPTED_IMAGE_KEYS['all'],
  maxFileSize: 5,
  form: false,
};

function FormImageEditorField(props) {
  const {
    name,
    defaultImage,
    label,
    companyType,
    editable,
    acceptedFileTypes,
    maxFileSize,
    isSubmitting,
    onSubmit,
    callback,
    form,
  } = props;
  const [isUploading, setIsUploading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [logo, setLogo] = useState(defaultImage || '');

  useEffect(() => {
    setLogo(defaultImage || '');
  }, [defaultImage]);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <React.Fragment>
      {form && (
        <input
          type="hidden"
          name={name}
          value={!!logo && !isString(logo) ? JSON.stringify(logo) : ''}
        />
      )}

      <FormField row label={label}>
        <Row className="align-items-center">
          <Col className="col-auto">
            <ContentAvatar
              file={logo}
              companyType={companyType}
              editable={editable}
              onClick={toggleOpen}
            />
          </Col>
          <Col className="col-auto text-muted">
            <small className="d-block mb-0">
              Supported file types:{' '}
              {ONLY_ACCEPTED_IMAGE_LABELS[acceptedFileTypes]}
            </small>
            <small>Max file size: {maxFileSize}MB</small>
          </Col>
        </Row>
      </FormField>

      <FormImageEditorModal
        isOpen={isOpen}
        callback={callback}
        toggleOpen={toggleOpen}
        defaultImage={logo}
        maxFileSize={maxFileSize}
        acceptedFileTypes={acceptedFileTypes}
        isSubmitting={isUploading || isSubmitting}
        onDelete={() => {
          if (form) setLogo('');
        }}
        onSubmit={async (formData) => {
          setIsUploading(true);
          try {
            let _data;
            if (formData) {
              const {data} = await uploadImage(formData);
              _data = data;
            }
            setLogo(_data || '');

            if (onSubmit)
              await onSubmit({
                [name]:
                  !!_data && !isString(_data) ? JSON.stringify(_data) : '',
              });
            toggleOpen();
          } catch (e) {
            alertByError(e);
          } finally {
            setIsUploading(false);
          }
        }}
      />
    </React.Fragment>
  );
}

export default FormImageEditorField;
