import styles from './styles.module.scss';
import React from 'react';
import classNames from 'classnames';
import {Col, Row} from 'reactstrap';
import {ButtonEdit} from '../Buttons';

const defaultProps = {
  editable: false,
  buttonProps: {},
};

function FormSectionHeader(props) {
  const {
    editable,
    title,
    className,
    isEdit,
    setIsEdit,
    children,
    buttonProps,
    ...rest
  } = props;

  return (
    <div
      className={classNames(styles['form-section__header'], {
        [className]: !!className,
      })}
      {...rest}
    >
      <Row className="align-items-center">
        <Col>
          <h3 className={styles['form-section__header__title']}>{title}</h3>
        </Col>
        {editable && (
          <Col sm="auto">
            <ButtonEdit
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              {...buttonProps}
            />
          </Col>
        )}
      </Row>

      {children}
    </div>
  );
}

FormSectionHeader.defaultProps = defaultProps;

export default FormSectionHeader;
