import React, {useEffect, useState, useRef} from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormText,
} from 'reactstrap';
import Dropzone from 'react-dropzone';
import ReactAvatarEditor from 'react-avatar-editor';
import {get, isEmpty} from 'lodash';

import {fileToImageUrl, dataURItoBlob} from 'utils/images';
import {alertByError, alertError, alertConfirm} from 'utils/alerts';
import {
  validateImage,
  IMAGES_FEEDBACK,
  ONLY_ACCEPTED_IMAGE,
  ONLY_ACCEPTED_IMAGE_KEYS,
  ONLY_ACCEPTED_IMAGE_LABELS,
} from 'configs';
import {returnFileSize} from 'utils/images';

import {Row, Col, FormGroup, Label, Input, CustomInput} from 'reactstrap';
import {BaseButton} from 'components/Buttons';
import {IconFA} from 'components/Icons';

const initialOptions = {
  position: {x: 0.5, y: 0.5},
  scale: 1,
  rotate: 0,
  border: 10,
  width: 160,
  height: 160,
  borderRadius: 0,
};

function Component({
  title,
  isOpen,
  toggleOpen,
  isLoading,
  defaultImage,
  maxFileSize = 5,
  acceptedFileTypes = ONLY_ACCEPTED_IMAGE_KEYS.all,
  //allowZoomOut,
  isSubmitting,
  onSubmit,

  deleteConfirmText = 'Are you sure you want to delete this photo?',
  deleteText = 'Delete photo',
  onDelete,
  callback,
}) {
  const fileRef = useRef();

  const [editor, setEditor] = useState({});
  const [image, setImage] = useState(
    !!defaultImage ? fileToImageUrl(defaultImage) : ''
  );
  const [position, setPosition] = useState(initialOptions.position);
  const [scale, setScale] = useState(initialOptions.scale);
  const [rotate, setRotate] = useState(initialOptions.rotate);
  const [border] = useState(initialOptions.border);
  const [width] = useState(initialOptions.width);
  const [height] = useState(initialOptions.height);
  const [borderRadius] = useState(initialOptions.borderRadius);

  useEffect(() => {
    if (isOpen) {
      setImage(!!defaultImage ? fileToImageUrl(defaultImage) : '');
    } else {
      // reset state after closing the modal
      setImage('');
      setPosition(initialOptions.position);
      setScale(initialOptions.scale);
      setRotate(initialOptions.rotate);
    }
  }, [isOpen, defaultImage]);

  function handleScale(e) {
    setScale(parseFloat(e.target.value));
  }

  function handleRotateLeft(e) {
    e.preventDefault();
    setRotate(rotate - 90);
  }

  function handleRotateRight(e) {
    e.preventDefault();
    setRotate(rotate + 90);
  }

  function handlePositionChange(position) {
    setPosition(position);
  }

  function handleDrop(acceptedFiles) {
    setImage(acceptedFiles[0]);
  }

  function handleRef(editor) {
    if (editor) setEditor(editor);
  }

  async function handleSave(e) {
    e.preventDefault();

    if (!image && isEmpty(defaultImage) && !fileToImageUrl(defaultImage)) {
      await alertError({
        text: `Please upload a image to save.`,
      });

      return;
    }

    const fileSize = returnFileSize(image.size);

    if (maxFileSize && maxFileSize <= fileSize) {
      await alertError({
        text: `Cannot send file size that is greater than ${maxFileSize} MB`,
      });
      return;
    }

    // Original file name from image.
    const imageName = get(image, 'name', 'image.png');

    // Get Data URL and convert to PNG.
    const dataURL = editor.getImageScaledToCanvas().toDataURL('image/png');
    const blob = dataURItoBlob(dataURL);

    let formData = new FormData();
    formData.append('image', blob, imageName);

    try {
      const res = await onSubmit(image ? formData : false);
      if (callback) callback(res);
    } catch (e) {
      await alertByError(e);
    }
  }

  async function handleNewImage(e) {
    const target = e.target;
    const image = target.files[0];
    const isValid = image ? validateImage(image.type) : false;

    if (isValid) {
      setImage(image);
    } else {
      const result = Object.keys(ONLY_ACCEPTED_IMAGE).find(
        (key) => ONLY_ACCEPTED_IMAGE[key] === acceptedFileTypes
      );

      await alertError({
        title: 'Invalid File Type',
        text: IMAGES_FEEDBACK[result],
      });

      target.value = ''; // Reset File Input
    }
  }

  async function handleDelete() {
    const {value} = await alertConfirm({text: deleteConfirmText});
    if (value) {
      fileRef.current.value = '';
      setImage('');
    }
    if (onDelete && value) onDelete();
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleOpen} centered>
      <ModalHeader toggle={toggleOpen}>{title || 'Image Upload'}</ModalHeader>
      <ModalBody>
        <Row>
          <Col sm="12" md="auto">
            <Dropzone
              onDrop={handleDrop}
              accept="image/*"
              disableClick
              style={{
                width: `${width + border * 2}px`,
                height: `${height + border * 2}px`,
              }}
            >
              <ReactAvatarEditor
                className="editor-canvas"
                crossOrigin="anonymous"
                ref={handleRef}
                image={image}
                border={border}
                scale={scale}
                width={width}
                height={height}
                position={position}
                onPositionChange={handlePositionChange}
                rotate={rotate}
                borderRadius={width / (100 / borderRadius)}
              />
            </Dropzone>

            {deleteText && !!image && (
              <Button
                outline
                color="white"
                className="text-danger d-block mx-auto mt-3"
                onClick={() => handleDelete()}
                disabled={isSubmitting}
              >
                {deleteText}
              </Button>
            )}
          </Col>
          <Col>
            <fieldset key={isLoading} disabled={isSubmitting}>
              <FormGroup>
                <Input
                  innerRef={fileRef}
                  id="file"
                  type="file"
                  name="file"
                  accept="image/*"
                  onChange={handleNewImage}
                />
                <FormText>
                  <p className="mb-0">
                    Supported file types:{' '}
                    {ONLY_ACCEPTED_IMAGE_LABELS[acceptedFileTypes]}
                  </p>
                  <p className="mb-0">Max file size: {maxFileSize}MB</p>
                </FormText>
              </FormGroup>
              <FormGroup row className="align-items-center">
                <Label for="zoom" sm={12} md={3}>
                  Zoom
                </Label>
                <Col>
                  <CustomInput
                    id="zoom"
                    type="range"
                    onChange={handleScale}
                    min="0.1"
                    max="2"
                    step="0.1"
                    defaultValue="1"
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label sm={12} md={3}>
                  Rotate
                </Label>

                <Col>
                  <Row noGutters>
                    <Col className="mr-1">
                      <BaseButton
                        color="primary"
                        outline
                        block
                        onClick={handleRotateLeft}
                      >
                        <IconFA name="rotate-left" /> Left
                      </BaseButton>
                    </Col>
                    <Col className="ml-1">
                      <BaseButton
                        color="primary"
                        outline
                        block
                        onClick={handleRotateRight}
                      >
                        <IconFA name="rotate-right" /> Right
                      </BaseButton>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            </fieldset>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline className="mx-2" onClick={toggleOpen}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave} disabled={isSubmitting}>
          {isSubmitting ? 'Saving...' : 'Save changes'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default Component;
