import React, {useState, useRef} from 'react';
import {isEmpty} from 'lodash';
import {
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import PropTypes from 'prop-types';
import {getFileExtension} from 'utils/documents';
import {alertError} from 'utils/alerts';

FileUploaderButton.propTypes = {
  name: PropTypes.string,
  accept: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  fileSizeLimit: PropTypes.number,
};

FileUploaderButton.defaultProps = {
  fileSizeLimit: 5,
  multiple: false,
  hasConfirmation: false,
  confirmationTitle: 'Upload',
  confirmationDescription: 'Are you sure you want to upload?',
  confirmationCancelButtonText: 'Cancel',
  confirmationCancelButtonProps: {
    color: 'dark',
    outline: true,
  },
  confirmationOkButtonText: 'Upload',
  confirmationOkButtonProps: {
    color: 'primary',
  },
};

function FileUploaderButton(props) {
  const {
    name,
    accept,
    fileSizeLimit,
    onSubmit,
    isSubmitting,
    multiple,
    children,
    hasConfirmation,
    confirmationTitle,
    confirmationDescription,
    confirmationCancelButtonText,
    confirmationCancelButtonProps,
    confirmationOkButtonText,
    confirmationOkButtonProps,
    disabled,
    ...rest
  } = props;
  const hiddenFileInput = useRef(null);
  const refConfirmation = useRef({});
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  function clearInputFile() {
    hiddenFileInput.current.value = null;
  }

  function handleClick() {
    clearInputFile();
    hiddenFileInput.current.click();
  }

  const showConfirm = async () => {
    return await new Promise((resolve, reject) => {
      refConfirmation.current = {
        resolve,
        reject,
      };

      setIsConfirmOpen(true);
    });
  };

  function handleClose() {
    const {resolve} = refConfirmation.current;
    resolve(false);

    setIsConfirmOpen(false);
  }

  function handleOk() {
    const {resolve} = refConfirmation.current;
    resolve(true);

    setIsConfirmOpen(false);
  }

  async function handleUpload(e) {
    e.persist();
    let shouldSubmit = true;

    if (hasConfirmation) {
      let result = await showConfirm();
      if (!result) return;
    }

    try {
      const files = Array.from(e.target.files);

      for (const file of files) {
        const {name, size} = file;
        const extension = getFileExtension(name, true);

        if (!accept.includes(extension)) {
          alertError({text: `Only accepted file is ${accept.join(', ')}`});
          shouldSubmit = false;
          break;
        }

        if (fileSizeLimit && fileSizeLimit * (1000 * 1000) <= size) {
          alertError({
            text: `Cannot send file size that is greater than ${fileSizeLimit} MB`,
          });
          shouldSubmit = false;
          break;
        }
      }

      if (shouldSubmit) await onSubmit(files);
    } catch (e) {
      alertError({text: 'Fail to upload. Please try again.'});
    }
  }

  return (
    <React.Fragment>
      <Modal isOpen={isConfirmOpen && hasConfirmation} centered>
        <ModalHeader toggle={() => handleClose()}>
          {confirmationTitle}
        </ModalHeader>
        <ModalBody>{confirmationDescription}</ModalBody>
        <ModalFooter>
          <Button
            {...confirmationCancelButtonProps}
            onClick={() => handleClose()}
          >
            {confirmationCancelButtonText}
          </Button>
          <Button {...confirmationOkButtonProps} onClick={() => handleOk()}>
            {confirmationOkButtonText}
          </Button>
        </ModalFooter>
      </Modal>

      <div className="custom-file-btn">
        <input
          type="file"
          accept={isEmpty(accept) ? '*' : accept.join(', ')}
          ref={hiddenFileInput}
          onChange={handleUpload}
          style={{display: 'none'}}
          multiple={multiple}
        />

        <Button
          onClick={() => handleClick()}
          disabled={isSubmitting || disabled}
          {...rest}
        >
          {isSubmitting ? <Spinner size="sm" /> : children}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default FileUploaderButton;
