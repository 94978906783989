import React from 'react';
import PropTypes from 'prop-types';

import {
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  Row,
  Label,
  Col,
  InputGroupText,
} from 'reactstrap';

import {IconFA} from 'components/Icons';
import {alertByError} from 'utils/alerts';
import {useToast} from 'contexts/ToastContext';
import urljoin from 'url-join';
import {ACTIVATE_CE} from 'configs';
import dayjs from 'dayjs';
import {isEmpty} from 'lodash';

CopyButton.propTypes = {
  type: PropTypes.oneOf(['code', 'link']).isRequired,
  isLoading: PropTypes.bool.isRequired,
  text: PropTypes.string,
};
function CopyButton(props) {
  const {type, isLoading, text} = props;
  const {toastSuccess} = useToast();

  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      toastSuccess({
        title: `Activation ${type} copied!`,
        date: false,
        body: null,
        position: 'top-right',
      });
    } catch (e) {
      await alertByError(e);
    }
  };

  return (
    <Button
      color="primary"
      className="text-left"
      onClick={handleCopyToClipboard}
      disabled={isLoading || !text}
      style={{width: '120px'}}
      outline
    >
      <IconFA name="clone" className="mr-2" />
      Copy {type}
    </Button>
  );
}

function FormActivate(props) {
  const {isLoading, isAdding, data} = props;

  const {activation_code, expiry_at} = data || {};
  const link = activation_code ? urljoin(ACTIVATE_CE, activation_code) : '';

  // API returns dates as unix timestamp seconds, so we need to convert it to milliseconds before calculating for the remaining duration
  const duration = dayjs.duration(
    dayjs(parseInt(expiry_at) * 1000, 'X') - dayjs()
  );
  const daysRemaining = Math.ceil(duration.asDays());
  const hoursRemaining = Math.ceil(duration.asHours());
  const minutesRemaining = Math.ceil(duration.asMinutes());
  const secondsRemaining = Math.ceil(duration.asSeconds());
  const timeRemaining =
    daysRemaining > 1
      ? `${daysRemaining} days`
      : hoursRemaining > 1
      ? `${hoursRemaining} hours`
      : minutesRemaining > 1
      ? `${minutesRemaining} minutes`
      : `${secondsRemaining} seconds`;

  const hasGenerated = !isEmpty(data) && duration > 0;

  return (
    <>
      <Row className="mb-2">
        <Label lg={3}>Activation Link</Label>

        <Col lg={7}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <IconFA name="link" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="No activation link generated"
              value={link}
              readOnly
            />

            <CopyButton
              type="link"
              isLoading={isLoading || isAdding}
              text={link}
            />
          </InputGroup>

          <small className="text-secondary">
            {hasGenerated
              ? `Activation link expires in ${timeRemaining}`
              : 'Activation link expires in 90 days upon generation'}
          </small>
        </Col>
      </Row>
      <Row>
        <Label lg={3}>Activation Code</Label>

        <Col lg={7}>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <IconFA name="shield" />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="No activation code generated"
              value={activation_code}
              readOnly
            />

            <CopyButton
              type="code"
              isLoading={isLoading || isAdding}
              text={activation_code}
            />
          </InputGroup>

          <small className="text-secondary">
            {hasGenerated
              ? `Activation code expires in ${timeRemaining}`
              : 'Activation code expires in 90 days upon generation'}
          </small>
        </Col>
      </Row>
    </>
  );
}

export default FormActivate;
