import React from 'react';
import {Link} from 'react-router-dom';

import {Button} from 'reactstrap';
import {IconFA} from 'components/Icons';
import ButtonEdit from './ButtonEdit';
import ButtonVideoGuide from './ButtonVideoGuide';
import ButtonDocGuide from './ButtonDocGuide';
import ButtonDevGuide from './ButtonDevGuide';
import ButtonDataTemplate from './ButtonDataTemplate';
import ButtonFileTemplate from './ButtonFileTemplate';
import ButtonFileUploader from './ButtonFileUploader';

export {
  ButtonEdit,
  ButtonVideoGuide,
  ButtonDocGuide,
  ButtonDevGuide,
  ButtonDataTemplate,
  ButtonFileTemplate,
  ButtonFileUploader,
};

export class BaseButton extends React.Component {
  static defaultProps = {
    text: '',
    to: '',
    color: 'primary',
    size: '',
    icon: '',
  };

  render() {
    const {text, to, color, size, icon, children, ...rest} = this.props;

    return (
      <Button
        to={to}
        tag={to ? Link : 'button'}
        color={color}
        size={size}
        {...rest}
      >
        {!!icon && <IconFA name={icon} fixedWidth className="mr-1" />}
        {!!text && <span>{text}</span>}
        {!!children && <span>{children}</span>}
      </Button>
    );
  }
}

export class ButtonLink extends React.Component {
  static defaultProps = {
    to: '',
    external: false,
  };

  render() {
    const {to, children, external, ...rest} = this.props;

    if (external) {
      return (
        <a href={to} {...rest}>
          {children}
        </a>
      );
    }

    return (
      <Link to={to} {...rest}>
        {!!children && children}
      </Link>
    );
  }
}
